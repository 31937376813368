import { IIconProps } from "@/components/icons/interface";

export const SaveIcon = ({ color }: IIconProps) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.87591 13.2721C2.63091 13.2721 2.40341 13.2138 2.19924 13.0971C1.75007 12.8346 1.49341 12.3038 1.49341 11.6446V3.41964C1.49341 1.93797 2.70091 0.730469 4.18257 0.730469H9.81174C11.2934 0.730469 12.5009 1.93797 12.5009 3.41964V11.6388C12.5009 12.298 12.2442 12.8288 11.7951 13.0913C11.3459 13.3538 10.7567 13.3246 10.1792 13.0038L7.33257 11.423C7.16341 11.3296 6.83091 11.3296 6.66174 11.423L3.81507 13.0038C3.50007 13.1788 3.17924 13.2721 2.87591 13.2721ZM4.18841 1.60547C3.19091 1.60547 2.37424 2.42214 2.37424 3.41964V11.6388C2.37424 11.983 2.47341 12.2396 2.64841 12.3388C2.82341 12.438 3.09757 12.4088 3.39507 12.2396L6.24174 10.6588C6.67341 10.4196 7.32674 10.4196 7.75841 10.6588L10.6051 12.2396C10.9026 12.4088 11.1767 12.4438 11.3517 12.3388C11.5267 12.2338 11.6259 11.9771 11.6259 11.6388V3.41964C11.6259 2.42214 10.8092 1.60547 9.81174 1.60547H4.18841Z"
        fill={color || "#ED028C"}
      />
      <path
        d="M6.46924 7.73031C6.35841 7.73031 6.24758 7.68948 6.16008 7.60198L5.28508 6.72698C5.11591 6.55781 5.11591 6.27781 5.28508 6.10865C5.45424 5.93948 5.73424 5.93948 5.90341 6.10865L6.46924 6.67448L8.49341 4.65031C8.66258 4.48115 8.94258 4.48115 9.11174 4.65031C9.28091 4.81948 9.28091 5.09948 9.11174 5.26865L6.77841 7.60198C6.69091 7.68948 6.58008 7.73031 6.46924 7.73031Z"
        fill={color || "#ED028C"}
      />
    </svg>
  );
};
